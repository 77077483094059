
export const isIE =
    /*@cc_on!@*/false ||
    // @ts-ignore
    (typeof window !== "undefined" && window.document && !!window.document.documentMode);

export const isEdge = (typeof window !== "undefined" && window.navigator.userAgent.indexOf("Edge") >= 0);

export const isWebView = (): boolean => {
    if (!window) {
        return false;
    }

    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    // @ts-ignore
    return (ios && !safari) || window.android;
};

export const isIFrame = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const isIOS =
    window.navigator.platform.match(/(iPhone|iPod|iPad)/i)
        ? true
        : false;
