import * as React from "react";

import * as _ from "lodash";

import { css, StyleSheet } from "aphrodite";

import Slider from "react-input-slider";
import IconEmbedPauseV2 from "../../assets/img/icons/IconEmbedPauseV2";
import IconEmbedPlayV2 from "../../assets/img/icons/IconEmbedPlayV2";
import theme from "../../theme";
import { isEdge, isIE } from "../../utils/browser";
import { toMMSS } from "../../utils/time";
import AudioPlayerProgressBar from "./../AudioPlayerProgressBar";

const listenOnNoa = "https://noa-assets.s3-eu-west-1.amazonaws.com/listenOnNoa.png";

interface IProps {
  feedbackEmail: string;
  linkToNoa: string;
  noaUrl: string;
  title: string;
  subtitle: string;
  imageUrl?: string;
  color?: string;
  bgColor?: string;
  currentTime: number;
  length: number;
  speed: number;
  playing: boolean;
  onClickFeedback: () => void;
  onClickListenOnNoa: () => void;
  onSpeedUp: () => void;
  onSpeedDown: () => void;
  onTogglePlay: () => void;
  onChange: (num: any) => void;
  onClick: () => void;
}

class PlayerStandardV2 extends React.Component<IProps> {

  public render() {
    const { currentTime, length, playing, linkToNoa } = this.props;

    const audioLength = Math.round(length);
    const time = Math.round(currentTime);

    return (
      <div className={css(styles.main)}>

        <div onClick={this.props.onTogglePlay}
          className={css(styles.playIcon, styles.noSelection, styles.playPauseIconWrapper, isIE && styles.playPauseIconIE)}>
          {playing ?
            <IconEmbedPauseV2 className={css(styles.playPauseIcon)} /> :
            <IconEmbedPlayV2 className={css(styles.playPauseIcon)} />
          }
        </div>

        {/* COL: TEXT & BUTTON | SEEKBAR & TIME */}
        <div className={css(styles.contentWrapper)}>

          {/* ROW: TEXT | BUTTON */}
          <div className={css(styles.textAndButtonWrapper)}>

            {/* TEXT */}
            <div className={css(styles.textWrapper)}>
              <h1 className={css(styles.title)}>
                <span onClick={this.props.onTogglePlay}
                  className={css(styles.highlightedText)}>Listen
                                </span> to this article
                            </h1>
              <h3 className={css(styles.desc)}>Brought to you by
                                <a href={linkToNoa} target="_blank" className={css(styles.link)}>
                  <span
                    className={css(styles.highlightedText)}
                    onClick={this.props.onClickListenOnNoa}> Noa | Understand the News</span>
                </a>
              </h3>
              <h3 className={css(styles.desc, styles.mobileDesc)}>Noa | Understand the News</h3>
            </div>

            {/* BUTTON */}
            <a href={linkToNoa} target="_blank" className={css(styles.link)}>
              <button
                className={css(styles.noaButton, isIE && styles.noaButtonIE)}
                onClick={this.props.onClickListenOnNoa}>
                <div className={css(isIE ? styles.buttonTextWrapper : styles.buttonLogoWrapper)}>
                  {isIE ?
                    <p className={css(styles.buttonText)}>Listen on Noa</p> :
                    <img className={css(styles.buttonLogo)} src={listenOnNoa} />
                  }
                </div>
              </button>
            </a>
          </div>

          {/* SEEKBAR */}
          {/* height={isMobile ? 2 : 5} */}
          <div className={css(styles.seekbarWrapper)}>

            {!isIE && !isEdge ?
              <AudioPlayerProgressBar
                max={audioLength}
                value={time}
                onChange={this.props.onChange}
                onClick={this.props.onClick}
                className={css(styles.seekbar)}
                height={4}
                color={"039be5"}
                bgColor={"#FFF"}
              />
              :
              <Slider
                axis={"x"}
                xmin={0}
                xmax={audioLength}
                xstep={1}
                x={time}
                onChange={({ x }) => { this.props.onChange(x); }}
                styles={{
                  active: {
                    backgroundColor: "#039be5",
                  },
                  thumb: {
                    height: 15,
                    width: 15,
                  },
                  track: {
                    backgroundColor: "#FFFFFF",
                    height: 5,
                    width: "100%",
                  },
                }}
              />
            }

            <span className={css(styles.seektime)}>
              <span>{toMMSS(time)}</span> / <span>{toMMSS(audioLength)}</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

// tslint:disable: object-literal-sort-keys
const styles = StyleSheet.create({
  main: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      padding: "18px",
      borderRadius: 15,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      padding: "14px",
      borderRadius: 10,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      padding: "15px",
    },
    padding: "25px",
    borderRadius: 20,
    display: "flex",
    width: "100%",
    backgroundColor: "black",
    flexDirection: "row",
    color: "white",
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  content: {
  },
  playIcon: {
    cursor: "pointer",
  },
  playPauseIconIE: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      height: 60,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      height: 55,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      height: 40,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      height: 35,
    },
    height: 80,
  },
  playPauseIconWrapper: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      width: 65,
      height: 72,
      marginRight: 20,
      marginTop: 6,
      marginBottom: 6,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      width: 55,
      height: 61,
      marginRight: 20,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      width: 40,
      height: 44,
      marginRight: 10,
      marginTop: 8,
      marginBottom: 8,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      width: 35,
      height: 39,
      marginRight: 10,
      marginTop: 9,
      marginBottom: 9,
    },
    width: 90,
    height: 99,
    marginRight: 25,
    marginTop: 3,
    marginBottom: 3,
  },
  playPauseIcon: {
    height: "100%",
    width: "100%",
    fill: "#fff",
    stroke: "#fff",
  },
  // @ts-ignore
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  textAndButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  textWrapper: {
    flexGrow: 1,
  },
  title: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 28,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 26,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      fontSize: 20,
    },
    [`@media (max-width: 400px)`]: {
      fontSize: 18,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      fontSize: 16,
    },
    [`@media (max-width: 340px)`]: {
      fontSize: 14,
    },
    fontSize: 38,
    fontWeight: 600,
    margin: 0,
    padding: 0,
    lineHeight: 0.7,
  },
  desc: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 12,
    },
    [`@media (max-width: 600px)`]: {
      fontSize: 12,
    },
    [`@media (max-width: 540px)`]: {
      display: "none",
    },
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    padding: 0,
    marginTop: 10,
  },
  mobileDesc: {
    [`@media (max-width: 540px)`]: {
      display: "block",
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      fontSize: 11,
      marginTop: 5,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      fontSize: 10,
    },
    display: "none",
  },
  highlightedText: {
    color: "#039be5",
    cursor: "pointer",
  },
  link: {
    ":before": {
      textDecoration: "none",
    },
    "textDecoration": "none",
  },
  // tslint:disable: object-literal-key-quotes
  noaButton: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      padding: 7,
      borderRadius: 5,
    },
    [`@media (max-width: 600px)`]: {
      padding: 7,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      padding: 7,
    },
    [`@media (max-width: 425px)`]: {
      padding: 5,
    },
    padding: 10,
    border: "none",
    backgroundColor: theme.tertiaryColor,
    fontWeight: 500,
    borderRadius: 10,
    borderColor: theme.tertiaryColor,
    cursor: "pointer",
    "-webkit-appearance": "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  noaButtonIE: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      padding: "3px 6px",
    },
    [`@media (max-width: 600px)`]: {
      padding: "3px 6px",
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      padding: "2px 5px",
    },
    [`@media (max-width: 425px)`]: {
      padding: "2px 5px",
    },
    padding: "3px 7px",
  },
  buttonTextWrapper: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 21,
    },
    [`@media (max-width: 600px)`]: {
      fontSize: 19,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 17,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      fontSize: 17,
    },
    [`@media (max-width: 425px)`]: {
      fontSize: 15,
      paddingLeft: 2,
      paddingRight: 2,
    },
    [`@media (max-width: 375px)`]: {
      fontSize: 13,
      padding: 0,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      fontSize: 12,
    },
    fontSize: 24,
    paddingLeft: 5,
    paddingRight: 5,
  },
  buttonLogoWrapper: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      width: 140,
      fontSize: 21,
    },
    [`@media (max-width: 600px)`]: {
      width: 140,
      fontSize: 19,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      width: 120,
      fontSize: 17,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      width: 120,
      fontSize: 17,
    },
    [`@media (max-width: 425px)`]: {
      width: 105,
      fontSize: 15,
    },
    [`@media (max-width: 375px)`]: {
      width: 100,
      fontSize: 13,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      width: 90,
      fontSize: 12,
    },
    width: 190,
    fontSize: 24,
    paddingTop: "14%",
    position: "relative",
  },
  buttonLogo: {
    height: "100%",
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
    "-ms-interpolation-mode": "bicubic",
  },
  buttonText: {
    color: "white",
    textDecoration: "none",
    margin: 0,
    padding: 0,
  },
  seekbarWrapper: {
    width: "100%",
    marginTop: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: -5,
  },
  seekbar: {
    borderRadius: 5,
    flex: 1,
  },
  seektime: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 13,
      minWidth: 85,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 12,
      minWidth: 80,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      fontSize: 11,
      minWidth: 70,
    },
    fontSize: 15,
    minWidth: 100,
    textAlign: "right",
    margin: 0,
    padding: 0,
    flex: "0 1 auto",
    marginLeft: "2%",
  },
  noSelection: {
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-ms-user-select": "none",
  },
});

export default PlayerStandardV2;
