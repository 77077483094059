import * as React from "react";

import theme from "../theme/index";
import { isIE } from "../utils/browser";

interface IProps {
    max: number;
    value: number;
    onChange: (value: number) => void;
    onClick: () => void;
    className?: string;
    color?: string;
    bgColor?: string;
    disabled?: boolean;
    hideThumb?: boolean;
    height?: number;
    progressBorderColor?: string;
}

class AudioPlayerProgressBar extends React.Component<IProps> {
    private range: any;

    constructor(props: IProps) {
        super(props);
    }

    public getColorToApply = () => {
        const { color, disabled } = this.props;

        if (disabled) {
            return theme.textColor;
        }

        return color ? `#${color}` : theme.tertiaryColor;
    };

    public updateProgressStyle = (value: number) => {
        const { max, bgColor } = this.props;

        const percentage = value / max;
        const bgColorToApply = bgColor ? bgColor : "#ddd";

        if (!isIE) {
            this.range.style.backgroundImage = `-webkit-gradient(linear, left top, right top, color-stop(${percentage}, ${this.getColorToApply()}), color-stop(${percentage}, ${bgColorToApply}))`;
        }
    };

    public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value;

        this.props.onChange(value);

        this.updateProgressStyle(value);
    };

    public applyStyle = () => {
        const { hideThumb, bgColor, height } = this.props;
        const colorToApply = this.getColorToApply();
        const bgColorToApply = bgColor ? bgColor : "#ddd";
        const heightToApply = !!height ? height : 5;

        if (isIE) {
            return `
                #noa-audio-player-progress-bar {
                padding: 0;
                }
            `;
        }

        return `
      @media only screen and (max-width: 575px) {
        #noa-audio-player-progress-bar {
          -webkit-appearance: none;
          -moz-apperance: none;
          height: ${heightToApply}px;
          background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(0, ${colorToApply}),
            color-stop(0, ${bgColorToApply})
          );
          cursor: pointer;
        }

        #noa-audio-player-progress-bar::-webkit-slider-thumb {
          -webkit-appearance: none !important;
          background-color: ${colorToApply};
          border: 0px;
          height: ${!!hideThumb ? "0px" : "12px"};
          width: ${!!hideThumb ? "0px" : "12px"};
          border-radius: ${!!hideThumb ? "0px" : "12px"};
        }
      }

      @media only screen and (min-width: 575px) {
        #noa-audio-player-progress-bar {
          -webkit-appearance: none;
          -moz-apperance: none;
          height: ${heightToApply}px;
          background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(0, ${colorToApply}),
            color-stop(0, ${bgColorToApply})
          );
          cursor: pointer;
        }

        #noa-audio-player-progress-bar::-webkit-slider-thumb {
          -webkit-appearance: none !important;
          background-color: ${colorToApply};
          border: 0px;
          height: ${!!hideThumb ? "0px" : "15px"};
          width: ${!!hideThumb ? "0px" : "15px"};
          border-radius: ${!!hideThumb ? "0px" : "15px"};
        }
      }
    `;
    };

    public componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.value !== this.props.value) {
            this.updateProgressStyle(this.props.value);
        }
    };

    public render() {
        const { max, value, className, disabled } = this.props;
        const classNameProp = className ? { className } : {};
        const disabledProp = disabled ? { disabled: true } : { disabled: false };

        return (
            <>
                <style>{this.applyStyle()}</style>
                <input
                    id="noa-audio-player-progress-bar"
                    type="range"
                    max={max}
                    value={value}
                    onChange={this.onChange}
                    onClick={this.props.onClick}
                    style={{ width: "100%", borderTop: `1px solid ${this.props.progressBorderColor || "#c8c8c8"}` }}
                    ref={(node) => {
                        this.range = node;
                    }}
                    {...disabledProp}
                    {...classNameProp}
                />
            </>
        );
    }
}

export default AudioPlayerProgressBar;
