import * as React from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { RouteComponentProps } from "react-router-dom";
import config from "../config";
import IArticle from "../types/IArticle";
import responseCheck from "../utils/response-check";
import { getQueryString } from "../utils/url";

interface IState {
    article?: IArticle;
}

interface IQSParams {
    key: string;
    id: string;
    bgColor: string;
    color: string;
    listenColor: string;
    controlColor: string;
    titleColor: string;
    timeColor: string;
    progressBgColor: string;
    speedColor: string;
    noaLinkColor: string;
    noaLinkHighlightColor: string;
    progressBorderColor: string;
}

class EmbedPlayerWrapper extends React.Component<RouteComponentProps, IState> {
    public LazyEmbedPlayer = React.lazy(() => import("./EmbedPlayer"));

    constructor(props: any) {
        super(props);

        this.state = {
            article: null,
        };
    }

    public getQueryParams = (): IQSParams => {
        const pathname = window.location.search;
        const queries = getQueryString(pathname);
        const key = queries.key || "";
        const id = queries.id || "";
        const color = queries.color || "";
        const bgColor = queries.bgColor || "";
        const listenColor = queries.listenColor || "";
        const controlColor = queries.controlColor || "";
        const titleColor = queries.titleColor || "";
        const timeColor = queries.timeColor || "";
        const progressBgColor = queries.progressBgColor || "";
        const speedColor = queries.speedColor || "";
        const noaLinkColor = queries.noaLinkColor || "";
        const noaLinkHighlightColor = queries.noaLinkHighlightColor || "";
        const progressBorderColor = queries.progressBorderColor || "";

        return { key, id, color, bgColor, listenColor, controlColor, titleColor, timeColor, progressBgColor, speedColor, noaLinkColor, noaLinkHighlightColor, progressBorderColor };
    };

    public getVersion = (): number => {
        const pathname = this.props.location.pathname.toLowerCase();

        if (pathname === "/v1") {
            return 1;
        }
        if (pathname === "/v2") {
            return 2;
        }
        if (pathname === "/v3") {
            return 3;
        }
        if (pathname === "/v4") {
            return 4;
        }

        return 1;
    };

    public getEmbedArticle = (publisherCode: string, articleCode: string) => {
        const requestUrl = `${config.api.url}${config.api.routes.articlePlayer}?code=${articleCode}&offPlatform=true`;
        const headers = {
            authorization: publisherCode,
        };

        return fetch(requestUrl, { headers })
            .then(responseCheck)
            .then((response) => {
                if (!!response && !!response.data && !!response.data.article) {
                    const article: IArticle = response.data.article;
                    this.setState({ article });
                }
            })
            .catch((error) => this.articleFailure(error));
    };

    public articleFailure = (error: Error) => {
        // tslint:disable-next-line: no-console
        console.error(error);
    };

    public getOfferId = () => {
        const { article } = this.state;

        const publisherNameLowered = article.publisher.name.toLowerCase();

        const publisherNameUnderscored = publisherNameLowered.split(" ").join("_");

        const offerId = publisherNameUnderscored + "_offer";

        return offerId;
    };

    public getLinkToNoa = (publisherKey: string) => {
        const { article } = this.state;

        const publishersWithOffers = [config.embed.publishers.hbr];

        const noaLink = "http://bit.ly/downloadNoa";

        if (!article || !publishersWithOffers.includes(publisherKey)) {
            // return config.site.url;
            return noaLink;
        }

        const params = this.getQueryParams();

        let offerId = this.getOfferId();

        if (publisherKey === config.embed.publishers.hbr) {
            offerId = "hbr_reader_exclusive_fng6lh";
        }

        if (publisherKey === config.embed.publishers.noema) {
            offerId = "noema_reader_exclusive_njs9kw";
        }

        // else if (publisherKey === config.embed.publishers.fastCompany) {
        //     offerId = "fastcompany_stdpx_6mt";
        // } else if (publisherKey === config.embed.publishers.conversation) {
        //     offerId = "theconversation_stdpx_6mt";
        // } else if (publisherKey === config.embed.publishers.tls) {
        //     offerId = "thetls_stdpx_6mt";
        // } else if (publisherKey === config.embed.publishers.mittr) {
        //     offerId = "techreview_stdpx_6mt";
        // }

        return `${config.site.url}?embedPubName=${article.publisher.name}&embedPubId=${article.publisher.id}&offerId=${offerId}&publisher=${publisherKey}&article=${params.id}`;
    };

    public getSubtitle = () => {
        const { article } = this.state;

        return article ? `${article.publisher.name} • ${Array.isArray(article.journalists) && article.journalists.length > 0 ? article.journalists[0].name : ""}` : "";
    };

    public componentDidMount() {
        const params = this.getQueryParams();
        this.getEmbedArticle(params.key, params.id);
    }

    public render() {
        const article = this.state.article;
        const { color, bgColor, key, listenColor, controlColor, titleColor, timeColor, progressBgColor, speedColor, noaLinkColor, noaLinkHighlightColor, progressBorderColor } = this.getQueryParams();
        this.getVersion();

        return (
            <React.Suspense fallback={<></>}>
                {!!article && (
                    <this.LazyEmbedPlayer
                        publisherKey={key}
                        queryParams={window.location.search}
                        version={this.getVersion()}
                        title={article.name}
                        audioUrl={article.audio}
                        feedbackEmail="feedback@newsoveraudio.com"
                        linkToNoa={this.getLinkToNoa(key)}
                        noaUrl={config.site.url}
                        subtitle={this.getSubtitle()}
                        metricName={`articles-${article.id}`}
                        color={color}
                        bgColor={bgColor}
                        listenColor={listenColor}
                        controlColor={controlColor}
                        article={this.state.article}
                        titleColor={titleColor}
                        timeColor={timeColor}
                        progressBgColor={progressBgColor}
                        speedColor={speedColor}
                        noaLinkColor={noaLinkColor}
                        noaLinkHighlightColor={noaLinkHighlightColor}
                        progressBorderColor={progressBorderColor}
                    />
                )}
            </React.Suspense>
        );
    }
}

export default EmbedPlayerWrapper;
