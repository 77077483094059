// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets//fonts/GT-America-Standard-Light.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets//fonts/GT-America-Standard-Light.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets//fonts/GT-America-Standard-Light.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets//fonts/GT-America-Standard-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets//fonts/GT-America-Standard-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets//fonts/GT-America-Standard-Bold.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face {\n    font-family: \"GT-America-Standard-Light\";\n    font-style: normal;\n    font-weight: 400;\n    src: local(\"GT-America-Standard-Light\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"eot\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");\n}\n\n@font-face {\n    font-family: \"GT-America-Standard-Bold\";\n    font-style: normal;\n    font-weight: 700;\n    src: local(\"GT-America-Standard-Bold\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"eot\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"opentype\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");\n}\n\n.hbrFont {\n    font-family: \"GT-America-Standard\" !important;\n}\n\nbody {\n    background: none !important;\n}\n", ""]);
// Exports
module.exports = exports;
