import * as _ from "lodash";

export const pathnameWithoutVersion = (urlString?: string) => {
    if (!urlString) {
        return "";
    }

    let pathname = "";

    const link = document.createElement("a");
    link.href = urlString;
    pathname = link.pathname;

    return `/${encodeURI(
        _.chain(pathname)
            .split("/")
            .takeRight(2)
            .join("/")
            .value(),
    )}`;
};

export const getQueryString = (search: string): { [key: string]: string } => {
    const cleanedSearch = _.size(search) > 0 && search[0] === "?" ? search.substr(1) : search;
    const vars = cleanedSearch.split("&");
    let qs = {};

    for (const v of vars) {
        const pair = v.split("=");

        if (_.size(pair) > 1) {
            qs = { ...qs, [pair[0]]: decodeURIComponent(pair[1]) };
        }
    }

    return qs;
};
