import * as React from "react";

import * as _ from "lodash";

import { css, StyleSheet } from "aphrodite";

import IconEmbedPause from "../../assets/img/icons/IconEmbedPause";
import IconEmbedPlay from "../../assets/img/icons/IconEmbedPlay";
import IconNoa from "../../assets/img/icons/IconNoa";
import text from "../../locale";
import theme from "../../theme";
import { toMMSS } from "../../utils/time";
import AudioPlayerProgressBar from "./../AudioPlayerProgressBar";

interface IProps {
  feedbackEmail: string;
  linkToNoa: string;
  noaUrl: string;
  title: string;
  subtitle: string;
  imageUrl?: string;
  color?: string;
  bgColor?: string;
  currentTime: number;
  length: number;
  speed: number;
  playing: boolean;
  onClickFeedback: () => void;
  onClickListenOnNoa: () => void;
  onSpeedUp: () => void;
  onSpeedDown: () => void;
  onTogglePlay: () => void;
  onChange: () => void;
  onClick: () => void;
}

class PlayerStandard extends React.Component<IProps> {

  public renderFeedback = () => {
    const { feedbackEmail } = this.props;

    return (
      <a
        id="embed-audio-player-feedback"
        href={`mailto:${feedbackEmail}`}
        className={css(styles.footerLink, styles.marginRight)}
        onClick={this.props.onClickFeedback}
      >
        {text.player.FEEDBACK}
      </a>
    );
  }

  public renderListenOnNoa = () => {
    const { linkToNoa } = this.props;

    return (
      <a
        id="embed-audio-player-listen-on-noa"
        href={linkToNoa}
        target="_blank"
        className={css(styles.blueFooterLink)}
        onClick={this.props.onClickListenOnNoa}
      >
        {text.player.LISTEN_ON}{text.player.NOA}
        <IconNoa className={css(styles.noaIcon, styles.marginLeft)} />
      </a>
    );
  }

  public renderSpeedControl = () => {
    const { speed } = this.props;

    return (
      <div className={css(styles.speedControl, styles.speedControl)}>
        <span className={css(styles.speed)} id="embed-audio-player-volume">
          {text.player.SPEED}: {speed}x
                </span>
        <div>
          <a
            className={css(styles.noSelection, styles.speedUp)}
            onClick={this.props.onSpeedUp}
            id="embed-audio-player-volume-up"
          >
            <code>+</code>
          </a>
          <a
            className={css(styles.noSelection, styles.speedDown)}
            onClick={this.props.onSpeedDown}
            id="embed-audio-player-volume-down"
          >
            <code>-</code>
          </a>
        </div>
      </div>
    );
  }

  public renderPlayAndPause = () => {
    const { color, playing } = this.props;
    const hexColor = color && `#${color}`;
    const colorStyle = color ? { backgroundColor: hexColor } : {};

    return (
      <div
        id="embed-audio-player-playpause"
        className={css(
          styles.noSelection,
          styles.togglePlayButton,
          !color && styles.defaultColor,
        )}
        style={colorStyle}
        onClick={this.props.onTogglePlay}
      >
        {playing && (
          <div className={css(styles.paddingPauseButton)}>
            <IconEmbedPause className={css(styles.playIcon)} />
          </div>
        )}
        {!playing && (
          <div className={css(styles.paddingPlayPauseButton, styles.paddingPlayButton)}>
            <IconEmbedPlay className={css(styles.playIcon)} />
          </div>
        )}
      </div>
    );
  }

  public renderTime = () => {
    const { currentTime, length } = this.props;

    const audioLength = Math.round(length);
    const time = Math.round(currentTime);

    return (
      <span className={css(styles.time)}>
        <span id="embed-audio-player-time-playing">{toMMSS(time)}</span> / <span>{toMMSS(audioLength)}</span>
      </span>
    );
  }

  public render() {
    const { color, bgColor, currentTime, length } = this.props;

    const audioLength = Math.round(length);
    const time = Math.round(currentTime);
    const bgStyle = bgColor ? { backgroundColor: `#${bgColor}` } : { background: `white` };

    return (
      <>
        <div>
          <div
            className={css(styles.container, !bgColor && styles.blur)}
            style={bgStyle}
          />
          <div className={css(styles.main)}>

            <div className={css(styles.line)}>
              <div className={css(styles.controls)}>
                {this.renderPlayAndPause()}
              </div>

              <div className={css(styles.content)}>
                {
                  <div className={css(styles.spaceBetween)}>
                    <p className={css(styles.title)}>{text.player.LISTEN_TO_THIS_ARTICLE}</p>
                    {this.renderSpeedControl()}
                  </div>
                }
                <div className={css(styles.progressContent)}>
                  <AudioPlayerProgressBar
                    max={audioLength}
                    value={time}
                    onChange={this.props.onChange}
                    onClick={this.props.onClick}
                    className={css(styles.progress)}
                    height={undefined}
                    color={color}
                    bgColor={undefined}
                  />
                </div>
                {(
                  <div className={css(styles.spaceBetween)}>
                    {this.renderTime()}
                    <div className={css(styles.links)}>
                      {this.renderFeedback()}
                      {this.renderListenOnNoa()}
                    </div>
                  </div>)}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// tslint:disable: object-literal-sort-keys
const styles = StyleSheet.create({
  main: {
    display: "flex",
    padding: "10px 10px 0px 10px",
    overflow: "hidden",
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  image: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      display: "none",
    },
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: 200,
    height: 200,
  },
  blueFooterLink: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 12,
    },
    color: theme.tertiaryColor,
    fontSize: 13,
    fontWeight: 400,
    textDecoration: "none",
  },
  footerLink: {
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      display: "none",
      fontSize: 12,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 12,
    },
    color: theme.iconColor,
    textDecoration: "none",
    fontSize: 13,
    fontWeight: 400,
  },
  marginRight: {
    marginRight: 15,
  },
  marginLeft: {
    marginLeft: 5,
  },
  controls: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      padding: "0px 10px 0px 0px",
    },
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 20px 0px 10px",
  },
  content: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      overflow: "hidden",
    },
    flex: 8,
  },
  speedUp: {
    fontWeight: 900,
    marginRight: 4,
    backgroundColor: "#fbfbfb",
    borderRadius: 20,
    fontSize: 14,
    color: theme.textColor,
    paddingBottom: 2,
    paddingRight: 5,
    paddingLeft: 5,
    border: "1px lightgrey solid",
    fontFamily: "monospace",
    cursor: "pointer",
  },
  speedDown: {
    fontWeight: 900,
    backgroundColor: "#fbfbfb",
    borderRadius: 20,
    fontSize: 14,
    color: theme.textColor,
    paddingBottom: 2,
    paddingRight: 5,
    paddingLeft: 5,
    border: "1px lightgrey solid",
    fontFamily: "monospace",
    cursor: "pointer",
  },
  speed: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 10,
      paddingTop: 6,
    },
    color: theme.embedTitle,
    fontWeight: 600,
    paddingRight: 10,
    fontSize: 13,
    paddingTop: 5,
  },
  togglePlayButton: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      width: 60,
      height: 60,
    },
    width: 65,
    height: 65,
    display: "flex",
    borderRadius: 90,
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    textAlign: "center",
  },
  playIcon: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      width: 25,
      height: 25,
    },
    width: 30,
    height: 30,
    fill: "white",
  },
  progress: {
    width: "100%",
  },
  title: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 16,
    },
    whiteSpace: "nowrap",
    paddingRight: 10,
    fontSize: 20,
    fontWeight: 500,
    color: theme.embedTitle,
    marginBottom: 5,
  },
  time: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 10,
    },
    fontSize: 12,
    fontWeight: 500,
    color: theme.embedTitle,
  },
  progressContent: {
    marginTop: -6,
  },
  noaIcon: {
    width: 18,
    height: 18,
    fill: theme.tertiaryColor,
    marginRight: 5,
    marginTop: -2,
  },
  container: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    zIndex: -10,
  },
  blur: {
    filter: "blur(50px) brightness(3)",
  },
  defaultColor: {
    backgroundColor: theme.tertiaryColor,
    color: theme.tertiaryColor,
  },
  noSelection: {
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-ms-user-select": "none",
  },
  paddingPauseButton: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      paddingLeft: "initial",
      paddingBottom: 2,
    },
  },
  paddingPlayPauseButton: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      paddingLeft: "initial",
      paddingBottom: 2,
    },
    paddingLeft: 6,
  },
  paddingPlayButton: {
    paddingLeft: 6,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  links: {
    display: "flex",
  },
  speedControl: {
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      display: "none",
    },
    display: "flex",
    flexDirection: "row",
  },
  line: {
    width: "100%",
    display: "flex",
  },
});

export default PlayerStandard;
