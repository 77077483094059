import * as _ from "lodash";

export enum EventCategories {
    AUDIO = "audio",
    EMBED_PLAYER = "embed-player",
}

export enum EventActions {
    TAP = "select",
    END = "end",
    PLAY = "play",
    PAUSE = "pause",
    LISTEN = "listen",
    LOAD = "load",
}

export enum EventLabels {
    EMBED_PLAYER_SPEED_UP = "embed-player-speed-up",
    EMBED_PLAYER_SPEED_DOWN = "embed-player-speed-down",
    EMBED_PLAYER_FEEDBACK = "embed-player-feedback",
    EMBED_PLAYER_LISTEN_ON_NOA = "embed-player-listen-on-noa",
}

interface IEvent {
    name: string | EventCategories;
    action: EventActions;
    label?: string | EventLabels;
    value?: number;
}
let eventsQueue: IEvent[] = [];

export const event =
    (name: string | EventCategories, action: EventActions, label?: string | EventLabels, value?: number) => {

        const labelStr = label ? _.kebabCase(label) : "";

        eventsQueue.push({ name, action, label: labelStr, value });

        const gtagFunc = getGtag();

        if (!gtagFunc) { return; }

        eventsQueue.forEach((e) => {
            console.log(`Noa - EV | Name: ${e.name} | Action: ${e.action} | Label: ${e.label} | Value: ${e.value}`);
            // tracker.send("event", e.name, e.action, e.label, e.value);
            // ga("send", "event", e.name, e.action, e.label, e.value);
            gtagFunc("event", e.action, {
                event_category: e.name,
                event_label: e.label,
                value: e.value,
            });
        });

        eventsQueue = [];
    };

const getGtag = () => {

    // @ts-ignore
    const gtagFunc = window.gtag;

    if (!gtagFunc) {
        console.log('Noa - No gtag found');
        return;
    }

    return gtagFunc;
};
