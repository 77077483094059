import * as React from "react";

interface IProps {
  className: string;
}

class IconNoa extends React.Component<IProps> {
  public render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700 700"
        className={this.props.className}
      >
        <g>
          <ellipse
            cx="79"
            cy="249"
            rx="75.04"
            ry="74.96"
            transform="translate(-152.93 128.79) rotate(-45)"
          />
          <path d="M117.56,247.11a306.18,306.18,0,0,1,37.38-63l-5.63,7.28a303.25,303.25,0,0,1,53.91-53.84l-7.29,5.63a306,306,0,0,1,67.12-39.07l-8.6,3.63a306.66,306.66,0,0,1,76.08-20.8L321,88.19a303.52,303.52,0,0,1,79.89-.11l-9.57-1.28a304.93,304.93,0,0,1,77,21.06l-8.6-3.63a306.29,306.29,0,0,1,67,39.05l-7.29-5.63a303.63,303.63,0,0,1,53.84,53.92l-5.62-7.29a306,306,0,0,1,39.06,67.12L603,242.8a305.72,305.72,0,0,1,20.8,76.07l-1.29-9.57a303.17,303.17,0,0,1,.11,79.9l1.29-9.57a305.46,305.46,0,0,1-21.06,77l3.63-8.6a306.9,306.9,0,0,1-39.06,67l5.63-7.28a303.25,303.25,0,0,1-53.91,53.84l7.28-5.63A306,306,0,0,1,459.31,595l8.6-3.63a306.66,306.66,0,0,1-76.08,20.8l9.57-1.29a303.52,303.52,0,0,1-79.89.11l9.57,1.28a304.81,304.81,0,0,1-77-21.06l8.59,3.63-3.9-1.68c-9.21-4-17.64-6.41-27.74-3.63-8.35,2.3-17.4,8.76-21.52,16.55-4.41,8.35-6.62,18.47-3.63,27.74,2.69,8.31,8.17,17.86,16.55,21.51C247,666,272.07,674.8,298.54,679.58c25.85,4.67,52.18,7,78.44,5.59a343.23,343.23,0,0,0,146.53-41.45c45.66-25,84.34-60.89,114.16-103.37a325.78,325.78,0,0,0,55.48-140.24c3.62-25.86,5.24-51.58,2.86-77.64-2.34-25.56-6.89-51-14.85-75.38A342,342,0,0,0,602.82,116c-37.05-38.52-83.4-67.07-133.76-84.6A324.53,324.53,0,0,0,322.27,16c-50.92,6.61-100,23-143.25,51.17A339.33,339.33,0,0,0,71.65,179a311.28,311.28,0,0,0-16.26,31.82c-4,9.17-6.4,17.69-3.63,27.75,2.3,8.35,8.76,17.4,16.55,21.51,8.35,4.41,18.47,6.63,27.74,3.63,8.33-2.7,17.81-8.17,21.51-16.55Z" />
          <path d="M465.72,375.62c-154.16,89-56.79,32.77-137.14,79.17-13.05,7.53-28.23,9.2-37.47-2.31-9-11.09-6.89-8.88-6.89-181.66,0-28.22,20-39.63,44.36-25.6,146.07,84.34,137.9,79.53,140.32,81.16a41.13,41.13,0,0,1,7.77,6.58C489.26,346.63,485.65,364.11,465.72,375.62Z" />
        </g>
      </svg>
    );
  }
}

export default IconNoa;
