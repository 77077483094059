import * as React from "react";

interface IProps {
    className?: string;
    style?: React.CSSProperties;
}

class IconHBRPlay extends React.Component<IProps> {
    public render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={this.props.className} style={this.props.style} height="24" viewBox="0 -960 960 960" width="24">
                <path d="M320-200v-560l440 280-440 280Z" />
            </svg>
        );
    }
}

export default IconHBRPlay;
