import * as React from "react";

import * as _ from "lodash";

import { css, StyleSheet } from "aphrodite";
import IconHBRPause from "../../assets/img/icons/IconHBRPause";
import IconHBRPlay from "../../assets/img/icons/IconHBRPlay";
import text from "../../locale";
import theme from "../../theme";
import { isEdge, isIE, isIOS } from "../../utils/browser";
import { toMMSS } from "../../utils/time";
import AudioPlayerProgressBar from "../AudioPlayerProgressBar";

import "./PlayerSpecial.css";
import config from "../../config";
import { getQueryString } from "../../utils/url";

interface IProps {
    feedbackEmail: string;
    linkToNoa: string;
    noaUrl: string;
    title: string;
    subtitle: string;
    imageUrl?: string;
    color?: string;
    bgColor?: string;
    currentTime: number;
    length: number;
    speed: number;
    playing: boolean;
    publisherKey: string;
    queryParams: string;
    onClickFeedback: () => void;
    onClickListenOnNoa: () => void;
    onSpeedUp: () => void;
    onSpeedDown: () => void;
    onTogglePlay: () => void;
    onChange: () => void;
    onClick: () => void;
    titleColor: string;
    timeColor: string;
    progressBgColor: string;
    speedColor: string;
    noaLinkColor: string;
    noaLinkHighlightColor: string;
    progressBorderColor: string;
}

class PlayerSpecial extends React.Component<IProps> {
    public renderListenOnNoa = () => {
        const { linkToNoa, noaLinkHighlightColor, noaLinkColor, publisherKey } = this.props;

        const prefix = /* [config.embed.publishers.hbr].includes(publisherKey) ? "Listen more on the Noa app. " : */ "To hear more, ";
        const suffix = /* [config.embed.publishers.hbr].includes(publisherKey) ? "Get 7 days free." : */ "download the Noa app";

        const highlightColor = noaLinkHighlightColor.length ? `#${noaLinkHighlightColor}` : theme.tertiaryColor;
        const color = noaLinkColor.length ? `#${noaLinkColor}` : "#282828";

        return (
            <a
                id="embed-audio-player-listen-on-noa"
                href={linkToNoa}
                rel="external"
                target="_blank"
                className={css(styles.blueFooterLink, styles.fontRegular)}
                onClick={this.props.onClickListenOnNoa}
                style={{ textDecoration: "none" }}
            >
                <span className={css(styles.footerLink)} style={{ color }}>
                    {prefix}
                </span>
                <span style={{ color: highlightColor }}>{suffix}</span>
            </a>
        );
    };

    public renderSpeedControl = () => {
        const { speedColor } = this.props;

        const color = speedColor.length ? `#${speedColor}` : "#6D6D6D";

        return (
            <div className={css(styles.speedControl)}>
                <span className={css(styles.speedTitle, styles.fontBold)} id="embed-audio-player-volume" style={{ color }}>
                    {text.player.SPEED}&nbsp;&nbsp;
                </span>

                <a className={css(styles.noSelection, styles.speedIcon, styles.fontRegular)} style={{ color }} onClick={this.props.onSpeedUp} id="embed-audio-player-volume-up">
                    +
                </a>

                <span className={css(styles.speedSeparator)} style={{ color }}>
                    &nbsp; | &nbsp;
                </span>

                <a className={css(styles.noSelection, styles.speedIcon, styles.fontRegular, styles.minusIcon)} style={{ color }} onClick={this.props.onSpeedDown} id="embed-audio-player-volume-down">
                    _
                </a>
            </div>
        );
    };

    public renderPlayAndPause = () => {
        const { playing, publisherKey } = this.props;

        const buttonColorStyle = this.getPlayButtonStyle();

        const isNoemaPublisherKey = publisherKey === config.embed.publishers.noema;

        return (
            <div
                id="embed-audio-player-playpause"
                className={css(styles.noSelection, styles.togglePlayButton, ...(isNoemaPublisherKey ? [styles.togglePlayButtonNoema] : []))}
                onClick={this.props.onTogglePlay}
            >
                {playing && (
                    <div className={css(styles.paddingPlayPauseButton, ...(isNoemaPublisherKey ? [styles.playPauseButtonNoema] : []))}>
                        <IconHBRPause className={css(styles.playIconPause, ...(isNoemaPublisherKey ? [styles.playIconNoema] : []))} style={buttonColorStyle} />
                    </div>
                )}
                {!playing && (
                    <div className={css(styles.paddingPlayPauseButton, ...(isNoemaPublisherKey ? [styles.playPauseButtonNoema] : []))}>
                        <IconHBRPlay className={css(styles.playIcon, ...(isNoemaPublisherKey ? [styles.playIconNoema] : []))} style={buttonColorStyle} />
                    </div>
                )}
            </div>
        );
    };

    public renderTime = () => {
        const { currentTime, length, timeColor } = this.props;

        const audioLength = Math.round(length);
        const time = Math.round(currentTime);

        const color = timeColor.length ? `#${timeColor}` : "#6D6D6D";

        return (
            <span className={css(styles.time, styles.fontRegular)} style={{ color }}>
                <span id="embed-audio-player-time-playing" className={css(styles.fixedTimes)} style={{ textAlign: "right" }}>
                    {toMMSS(time)}
                </span>{" "}
                &nbsp;/&nbsp;{" "}
                <span className={css(styles.fixedTimes)} style={{ textAlign: "left" }}>
                    {toMMSS(audioLength)}
                </span>
            </span>
        );
    };

    public getPlayButtonStyle = () => {
        const { queryParams } = this.props;

        const queryParamObject = getQueryString(queryParams);

        const playColor = queryParamObject["playColor"];

        let hex = "#000";

        if (playColor && playColor.length === 6) {
            hex = `#${playColor}`;
        }

        return { fill: hex };
    };

    public render() {
        const { color, bgColor, currentTime, length, publisherKey, titleColor, progressBgColor, progressBorderColor: pgBorderColor } = this.props;

        const audioLength = Math.round(length);
        const time = Math.round(currentTime);

        const bgStyle = bgColor ? { backgroundColor: `#${bgColor}` } : { background: `white` };
        const titleTextColor = titleColor.length ? `#${titleColor}` : theme.embedTitle;
        const progressBackgroundColor = progressBgColor.length ? `#${progressBgColor}` : "#F5F5F5";
        const progressBorderColor = pgBorderColor.length ? `#${pgBorderColor}` : "#C8C8C8";

        const isNoemaPublisherKey = publisherKey === config.embed.publishers.noema;

        return (
            <>
                <div>
                    <div className={css(styles.container)} />
                    <div className={css(styles.main, ...(isNoemaPublisherKey ? [styles.mainNoema] : []))} style={bgStyle}>
                        {/* MAIN CONTENT */}
                        <div className={css(styles.line, styles.mainInner)}>
                            <div className={css(styles.controls)}>
                                <p className={css(styles.title, styles.fontExtraBold)} style={{ color: titleTextColor }}>
                                    {text.player.LISTEN_TO_THIS_ARTICLE}
                                </p>
                                {this.renderSpeedControl()}
                            </div>

                            <div className={css(styles.content)}>
                                <div className={css(styles.progressContent)}>
                                    {this.renderPlayAndPause()}
                                    <AudioPlayerProgressBar
                                        max={audioLength}
                                        value={time}
                                        onChange={this.props.onChange}
                                        onClick={this.props.onClick}
                                        className={css(styles.progress)}
                                        height={6}
                                        color={color}
                                        bgColor={progressBackgroundColor}
                                        hideThumb={!isIOS}
                                        progressBorderColor={progressBorderColor}
                                    />
                                    {this.renderTime()}
                                </div>
                            </div>
                        </div>

                        <div className={css(styles.showOnTiny)}>
                            <p className={css(styles.title, styles.fontBold)} style={{ color: titleTextColor }}>
                                {text.player.LISTEN_TO_THIS_ARTICLE}
                            </p>
                        </div>
                    </div>

                    <div className={css(styles.links, styles.fontRegular)}>{this.renderListenOnNoa()}</div>
                </div>
            </>
        );
    }
}

// tslint:disable: object-literal-sort-keys
const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: "100vh",
        position: "absolute",
        zIndex: -10,
    },
    main: {
        [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
            flexDirection: "column",
            padding: "12px 24px 8px 24px",
        },
        display: "flex",
        padding: "18px 24px 12px 24px",
    },

    mainNoema: {
        borderRadius: "100px",
    },

    mainInner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    title: {
        // [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
        //     paddingBottom: 7,
        // },
        [`@media (min-width: ${theme.breakpoints.tiny}px)`]: {
            lineHeight: "14px",
        },
        whiteSpace: "nowrap",
        paddingRight: 10,
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 0,
    },
    showOnTiny: {
        [`@media (min-width: ${theme.breakpoints.tiny}px)`]: {
            display: "none",
        },

        marginTop: "5px",
    },
    image: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            display: "none",
        },
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: 200,
        height: 200,
    },
    blueFooterLink: {
        fontSize: 15,
        color: "#282828",
    },
    footerLink: {
        textDecoration: "none",
    },
    marginRight: {
        marginRight: 15,
    },
    controls: {
        [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
            display: "none",
        },
        display: "flex",
        flexDirection: "column",
        padding: "0px 20px 0px 0px",
    },
    content: {
        [`@media (min-width: ${theme.breakpoints.tiny}px)`]: {
            marginTop: -5,
        },
        flex: 1,
    },
    playPauseButtonNoema: {
        background: "#000000",
        width: "30px !important",
        height: "30px !important",
        borderRadius: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    togglePlayButton: {
        width: isIE ? 45 : 25,
        height: 30,
        display: "flex",
        borderRadius: 90,
        marginRight: 5,
        marginLeft: 1,
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",
        textAlign: "center",
    },

    togglePlayButtonNoema: {
        marginRight: "14px",
    },

    playIconNoema: {
        transform: "scale(1)",
    },

    playIcon: {
        // marginTop: isIE ? -10 : "initial",
        // width: 15,
        // height: 25,
        // color: "red",

        transform: "scale(1.3, 1.65)",
    },

    playIconPause: {
        // marginTop: isIE ? -10 : "initial",
        // width: 15,
        // height: 25,
        // color: "red",

        transform: "scale(1.22, 1.5)",
    },

    progress: {
        [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
            marginTop: 6,
        },
        marginTop: isIE ? -4 : 8,
        width: "100%",
    },
    time: {
        [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
            padding: "0px 0px 0px 10px",
        },
        marginTop: isIE ? -5 : "initial",
        marginRight: isEdge ? 15 : "initial",
        padding: "0px 0px 0px 10px",
        fontSize: 16,
        whiteSpace: "nowrap",
    },
    progressContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    noSelection: {
        "-webkit-user-select": "none",
        "-moz-user-select": "none",
        "-khtml-user-select": "none",
        "-ms-user-select": "none",
    },
    paddingPlayPauseButton: {
        // [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
        //     paddingLeft: "initial",
        //     paddingBottom: 2,
        // },
        // paddingLeft: 6,
    },
    links: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "6px 5px 0 0",
    },
    speed: {
        [`@media (max-width: ${theme.breakpoints.tiny - 1}px)`]: {
            fontSize: 10,
            paddingTop: 6,
        },
        color: theme.embedTitle,
        fontWeight: 600,
        paddingRight: 10,
        fontSize: 13,
        paddingTop: 5,
    },
    speedControl: {
        display: "flex",
        alignItems: "center",
    },
    speedTitle: {
        fontSize: 12,
        marginRight: "10px",
    },
    speedSeparator: {
        fontSize: 12,
        fontWeight: 100,
    },
    speedIcon: {
        fontSize: 18,
        fontWeight: 700,
        cursor: "pointer",
    },
    minusIcon: {
        marginTop: "-13px",
    },
    line: {
        width: "100%",
        display: "flex",
    },
    fixedTimes: {
        display: "inline-block",
        width: 45,
    },

    fontExtraBold: {
        fontWeight: 700,
        fontFamily: "Roboto, sans-serif",
    },
    fontBold: {
        fontWeight: 500,
        fontFamily: "Roboto, sans-serif",
    },
    fontRegular: {
        fontWeight: 400,
        fontFamily: "Roboto, sans-serif",
    },
});

export default PlayerSpecial;
