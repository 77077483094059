enum MpTrackingButtons {

  // Playback
  PLAY = 'Play',
  PAUSE = 'Pause',
  SCRUB = 'MoveProgressBar',
  ADJUST_VOLUME = 'AdjustVolume',
  ADJUST_SPEED = 'AdjustSpeed',

  // Navigation
  NAVIGATE_TO_NOA = 'NavigateToNoa',

  // Miscellaneous
  FEEDBACK = 'Feedback',
}

export default MpTrackingButtons;