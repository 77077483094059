import * as React from "react";

interface IProps {
  className: string;
}

class IconEmbedPause extends React.Component<IProps> {
  public render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 535.578 535.578"
        className={this.props.className}
      >
        <g>
          <g>
            <g>
              <path d="M231.6,516.278c0,10.658-8.641,19.3-19.3,19.3H106.15c-10.659,0-19.3-8.641-19.3-19.3V19.3     c0-10.659,8.641-19.3,19.3-19.3h106.15c10.659,0,19.3,8.641,19.3,19.3V516.278z" />
              <path d="M448.728,516.278c0,10.658-8.641,19.3-19.3,19.3h-106.15c-10.659,0-19.3-8.641-19.3-19.3V19.3     c0-10.659,8.641-19.3,19.3-19.3h106.15c10.659,0,19.3,8.641,19.3,19.3V516.278z" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconEmbedPause;
