import * as React from "react";

interface IProps {
    className?: string;
    style?: React.CSSProperties;
}

class IconHBRPause extends React.Component<IProps> {
    public render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={this.props.className} style={this.props.style} height="24" viewBox="0 -960 960 960" width="24">
                <path d="M560-200v-560h160v560H560Zm-320 0v-560h160v560H240Z" />
            </svg>
        );
    }
}

export default IconHBRPause;
