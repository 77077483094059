import { isValidJSON } from "./json";

const responseCheck = (response: Response): Promise<any> => {
    if (response.status >= 200 && response.status < 300) {
        return response.text().then((body) => {
            if (isValidJSON(body)) {
                return JSON.parse(body);
            }
            return Promise.resolve(body);
        });
    }

    if (response.status === 401) {
        throw new Error("Unauthorized");
    }

    throw new Error("Invalid Http Status");
};

export default responseCheck;
