import * as React from "react";

import * as _ from "lodash";

import { css, StyleSheet } from "aphrodite";

import Slider from "react-input-slider";
import IconEmbedPauseV2 from "../../assets/img/icons/IconEmbedPauseV2";
import IconEmbedPlayV2 from "../../assets/img/icons/IconEmbedPlayV2";
import theme from "../../theme";
import { isEdge, isIE } from "../../utils/browser";
import { toMMSS } from "../../utils/time";
import AudioPlayerProgressBar from "./../AudioPlayerProgressBar";

const listenOnNoa = "https://noa-assets.s3-eu-west-1.amazonaws.com/listenOnNoa.png";

interface IProps {
  feedbackEmail: string;
  linkToNoa: string;
  noaUrl: string;
  title: string;
  subtitle: string;
  imageUrl?: string;
  listenColor?: string;
  controlColor?: string;
  currentTime: number;
  length: number;
  speed: number;
  playing: boolean;
  onClickFeedback: () => void;
  onClickListenOnNoa: () => void;
  onSpeedUp: () => void;
  onSpeedDown: () => void;
  onTogglePlay: () => void;
  onChange: (num: any) => void;
  onClick: () => void;
}

const BREAKPOINT_850 = 850;

class PlayerStandardV3 extends React.Component<IProps> {

  public isMobileWidth = () => {
    return window.innerWidth <= theme.breakpoints.tiny;
  }

  public getListenColor = () => {
    return !!this.props.listenColor ? `#${this.props.listenColor}` : '#039be5';
  }

  public getControlColor = () => {
    return !!this.props.controlColor ? `#${this.props.controlColor}` : '#039be5';
  }

  public getDynamicStyles = () => {

    const listenColor = this.getListenColor();
    const controlColor = this.getControlColor();

    return StyleSheet.create({
      textListenColor: {
        color: listenColor,
      },
      bgListenColor: {
        backgroundColor: listenColor,
      },
      svgControlColor: {
        fill: controlColor,
        stroke: controlColor,
      }
    });
  }

  public getSeekbarHeight = () => {
    return this.isMobileWidth() ? 4 : 5
  }

  public render() {
    const { currentTime, length, playing, linkToNoa } = this.props;

    const dynamicStyles = this.getDynamicStyles();

    const audioLength = Math.round(length);
    const time = Math.round(currentTime);

    return (
      <div className={css(styles.container)}>

        {/* Gray section up top */}
        <div className={css(styles.mainBackground)}>

          {/* Col 1 | Title section */}
          <div className={css(styles.titleSection, styles.hideTiny)}>

            <h2 className={css(styles.title)}>
              <span
                onClick={this.props.onTogglePlay}
                className={css(styles.highlightedText, dynamicStyles.textListenColor)}>Listen
                            </span> to this article
                        </h2>
            <h5 className={css(styles.subTitle)}>Powered by Noa | Home of Audio Journalism</h5>
          </div>

          {/* Col 2 | Play button */}
          <div
            onClick={this.props.onTogglePlay}
            className={css(styles.playPauseWrapper, styles.noSelection)}>

            {playing ?
              <IconEmbedPauseV2 className={css(styles.playPauseIcon, dynamicStyles.svgControlColor)} /> :
              <IconEmbedPlayV2 className={css(styles.playPauseIcon, dynamicStyles.svgControlColor)} />
            }
          </div>

          <div className={css(styles.seekbarAndTitle)}>

            <div className={css(styles.titleSection, styles.showTiny)}>
              <h2 className={css(styles.title)}>
                <span
                  onClick={this.props.onTogglePlay}
                  className={css(styles.highlightedText, dynamicStyles.textListenColor)}>Listen
                                </span> to this article
                            </h2>
            </div>

            {/* Col 3 | Seekbar */}
            <div className={css(styles.seekbarWrapper)}>

              {!isIE && !isEdge ?
                <AudioPlayerProgressBar
                  max={audioLength}
                  value={time}
                  onChange={this.props.onChange}
                  onClick={this.props.onClick}
                  className={css(styles.seekbar)}
                  height={this.getSeekbarHeight()}
                  color={this.props.controlColor ? this.props.controlColor : "039be5"}
                  bgColor={"#BDBDBD"}
                />
                :
                <Slider
                  axis={"x"}
                  xmin={0}
                  xmax={audioLength}
                  xstep={1}
                  x={time}
                  onChange={({ x }) => { this.props.onChange(x); }}
                  styles={{
                    active: {
                      backgroundColor: this.getControlColor(),
                    },
                    thumb: {
                      height: 15,
                      width: 15,
                      backgroundColor: this.getControlColor(),
                    },
                    track: {
                      backgroundColor: "#BDBDBD",
                      height: this.getSeekbarHeight(),
                      width: "100%",
                    },
                  }}
                />
              }

              <div className={css(styles.seektime, styles.seekTimeShowSmall)}>
                <span>{toMMSS(time)}</span> / <span>{toMMSS(audioLength)}</span>
              </div>
            </div>
          </div>

          {/* Col 4 | Time */}
          <div className={css(styles.seektime, styles.seekTimeHideSmall)}>
            <span>{toMMSS(time)}</span> / <span>{toMMSS(audioLength)}</span>
          </div>
        </div>

        {/* Listen on Noa button */}
        <div className={css(styles.button)}>
          <a href={linkToNoa} target="_blank" className={css(styles.link)}>
            <button
              className={css(styles.noaButton, isIE && styles.noaButtonIE, dynamicStyles.bgListenColor)}
              onClick={this.props.onClickListenOnNoa}>
              <div className={css(isIE ? styles.buttonTextWrapper : styles.buttonLogoWrapper)}>
                {isIE ?
                  <p className={css(styles.buttonText)}>Listen on Noa</p> :
                  <img className={css(styles.buttonLogo)} src={listenOnNoa} />
                }
              </div>
            </button>
          </a>
        </div>
      </div>
    );
  }
}

// tslint:disable: object-literal-sort-keys
const styles = StyleSheet.create({
  container: {
    width: "100%",
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  mainBackground: {
    [`@media (max-width: ${BREAKPOINT_850}px)`]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      height: 70,
    },
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 30px",
    height: 80,
  },
  titleSection: {
    [`@media (max-width: ${theme.breakpoints.smaller}px)`]: {
      marginRight: 15,
    },
    flexDirection: "row",
    flex: "0 0 auto",
    marginRight: 20,
  },
  title: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 29,
    },
    [`@media (max-width: ${theme.breakpoints.smaller}px)`]: {
      fontSize: 27,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 24,
      fontWeight: 400,
      marginBottom: 13,
      paddingLeft: 1,
    },
    [`@media (max-width: 430px)`]: {
      fontSize: 22,
      marginBottom: 12,
    },
    [`@media (max-width: 400px)`]: {
      fontSize: 20,
      marginBottom: 10,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      fontSize: 18,
    },
    [`@media (max-width: 340px)`]: {
      fontSize: 16,
    },
    fontSize: 32,
    fontWeight: 500,
    margin: 0,
    padding: 0,
    lineHeight: 0.7,
  },
  subTitle: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 13,
    },
    [`@media (max-width: ${theme.breakpoints.smaller}px)`]: {
      fontSize: 12,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      fontSize: 11,
    },
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    padding: 0,
    marginTop: 10,
  },
  hideTiny: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      display: "none"
    },
  },
  showTiny: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      display: "block"
    },
    display: "none"
  },
  playPauseWrapper: {
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      marginRight: 12,
    },
    marginRight: 10,
  },
  playPauseIcon: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      width: 45,
    },
    [`@media (max-width: ${theme.breakpoints.smaller}px)`]: {
      width: 40,
    },
    height: "100%",
    width: 50,
    fill: "#039be5",
    stroke: "#039be5",
  },
  highlightedText: {
    color: "#039be5",
    cursor: "pointer",
    fontWeight: 500,
  },
  link: {
    ":before": {
      textDecoration: "none",
    },
    "textDecoration": "none",
  },
  button: {
    float: 'right',
  },
  // tslint:disable: object-literal-key-quotes
  noaButton: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      padding: "7px 13px",
      paddingRight: 11,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      padding: "7px 9px",
      paddingRight: 8,
    },
    padding: "8px 15px",
    paddingRight: 13,
    border: "none",
    backgroundColor: theme.tertiaryColor,
    fontWeight: 500,
    cursor: "pointer",
    "-webkit-appearance": "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  noaButtonIE: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      padding: "3px 6px",
    },
    [`@media (max-width: 600px)`]: {
      padding: "3px 6px",
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      padding: "2px 5px",
    },
    [`@media (max-width: 425px)`]: {
      padding: "2px 5px",
    },
    padding: "3px 7px",
  },
  buttonTextWrapper: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 21,
    },
    [`@media (max-width: 600px)`]: {
      fontSize: 19,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 17,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      fontSize: 17,
    },
    [`@media (max-width: 425px)`]: {
      fontSize: 15,
      paddingLeft: 2,
      paddingRight: 2,
    },
    [`@media (max-width: 375px)`]: {
      fontSize: 13,
      padding: 0,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      fontSize: 12,
    },
    fontSize: 24,
    paddingLeft: 5,
    paddingRight: 5,
  },
  buttonLogoWrapper: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      width: 130,
    },
    [`@media (max-width: ${theme.breakpoints.smaller}px)`]: {
      width: 120,
    },
    [`@media (max-width: ${theme.breakpoints.tinyier}px)`]: {
      width: 110,
    },
    [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
      width: 90,
    },
    width: 140,
    paddingTop: "14%",
    position: "relative",
  },
  buttonLogo: {
    height: "100%",
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
    "-ms-interpolation-mode": "bicubic",
  },
  buttonText: {
    color: "white",
    textDecoration: "none",
    margin: 0,
    padding: 0,
  },
  seekbarAndTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  seekbarWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
  },
  seekbar: {
    flex: 1,
  },
  seekTimeShowSmall: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      display: "block",
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      display: "block",
      bottom: 10,
      top: 'auto',
    },
    display: "none",
    position: "absolute",
    right: 1,
    bottom: 'auto',
    top: 10,
  },
  seekTimeHideSmall: {
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      display: "none",
    },
  },
  seektime: {
    [`@media (max-width: ${BREAKPOINT_850}px)`]: {
      fontSize: 18,
      minWidth: 113,
    },
    [`@media (max-width: ${theme.breakpoints.small}px)`]: {
      fontSize: 13,
    },
    [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
      fontSize: 13,
    },
    fontSize: 20,
    minWidth: 125,
    textAlign: "right",
    margin: 0,
    padding: 0,
    paddingBottom: 2,
    flex: "0 0 auto",
  },
  noSelection: {
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-khtml-user-select": "none",
    "-ms-user-select": "none",
  },
});

export default PlayerStandardV3;
