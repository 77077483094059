import IArticle from "../types/IArticle";
import MpTrackingButtons from "../types/mpTrackingButtons";

export const mpSetUserProperties = () => {
    const trackingPoperties = {
        UserPlatform: "EMBED_PLAYER",
    };

    // @ts-ignore
    // Send all events to embed tracking user
    window.mixpanel.identify(47347);

    // @ts-ignore
    window.mixpanel.people.set(trackingPoperties);

    // @ts-ignore
    window.mixpanel.register(trackingPoperties);
};

// EVENTS

export const mpTrackIntervalListen = (article: IArticle, currentProgress: number) => {
    const trackingPoperties = {
        ArticleName: article.name,
        ArticleId: article.id,
        PublisherName: article.publisher.name,
        AudioLengthSecs: Math.round(article.audioLength),
        ListenProgressSecs: Math.round(currentProgress),
        ListenProgressPercent: +((Math.round(currentProgress) / Math.round(article.audioLength)) * 100).toFixed(2),
    };

    trackEvent("ArticleListenInterval", trackingPoperties);
};

export const mpTrackButtonClick = (article: IArticle, button: MpTrackingButtons) => {
    const trackingPoperties = {
        ArticleName: article.name,
        ArticleId: article.id,
        PublisherName: article.publisher.name,
        ButtonName: button.toString(),
    };

    trackEvent("ButtonClick", trackingPoperties);
};

const trackEvent = async (eventName: string, eventProps: any) => {
    // @ts-ignore
    if (!window.mixpanel) {
        return;
    }

    mpSetUserProperties();

    // @ts-ignore
    window.mixpanel.track(eventName, eventProps);

    // @ts-ignore
    if (window.fbq) {
        // @ts-ignore
        window.fbq("track", eventName, eventProps);
    }

    console.log(`Noa - MP - FB - ${eventName}:\n${JSON.stringify(eventProps)}\n`);
};
