import * as React from "react";

import * as _ from "lodash";

import config from "../config";
import { isIFrame, isWebView } from "../utils/browser";
import { event, EventActions, EventCategories, EventLabels } from "../utils/metric";
import { mpTrackButtonClick, mpTrackIntervalListen } from "../utils/mixpanel";
import IArticle from "../types/IArticle";
import MpTrackingButtons from "../types/mpTrackingButtons";
import { sleep } from "../utils/async";

interface IProps {
    audioUrl: string;
    feedbackEmail: string;
    linkToNoa: string;
    noaUrl: string;
    title: string;
    subtitle: string;
    metricName: string;
    imageUrl?: string;
    color?: string;
    bgColor?: string;
    publisherKey: string;
    version: number;
    listenColor?: string;
    controlColor?: string;
    article?: IArticle;
    queryParams: string;
    titleColor: string;
    timeColor: string;
    progressBgColor: string;
    speedColor: string;
    noaLinkColor: string;
    noaLinkHighlightColor: string;
    progressBorderColor: string;
}

interface IState {
    audio?: HTMLAudioElement;
    currentTime: number;
    length: number;
    speed: number;
    playing: boolean;
    loading: boolean;
    audioLengthTracked: number;
}

const MAX_SPEED = 2;
const MIN_SPEED = 0.5;
const PLAYER_HTML_ID = "embed-audio-player";
const AUDIO_FOUND = "NOA_AUDIO_FOUND";
const TRACKING_LISTEN_INCREMENT = 10;

class EmbedPlayer extends React.Component<IProps, IState> {
    public LazyStandardPlayer = React.lazy(() => import("./playerVerions/PlayerStandard"));
    public LazyStandardPlayerV2 = React.lazy(() => import("./playerVerions/PlayerStandardV2"));
    public LazyStandardPlayerV3 = React.lazy(() => import("./playerVerions/PlayerStandardV3"));
    public LazyPlayerSpecial = React.lazy(() => import("./playerVerions/PlayerSpecial"));

    constructor(props: IProps) {
        super(props);

        this.state = {
            audioLengthTracked: -TRACKING_LISTEN_INCREMENT,
            currentTime: 0,
            length: 0,
            loading: false,
            playing: false,
            speed: 1,
        };
    }

    public setAudioDefault = () => {
        const { metricName, article } = this.props;
        const { length } = this.state;

        const audio = document.getElementById(PLAYER_HTML_ID) as HTMLAudioElement;

        audio.ontimeupdate = () => {
            const audioLengthTracked = this.state.audioLengthTracked;

            if (audioLengthTracked + TRACKING_LISTEN_INCREMENT < audio.currentTime) {
                const newAudioLengthTracked = Math.floor(audio.currentTime / 10) * 10;
                event(EventCategories.EMBED_PLAYER, EventActions.LISTEN, `${metricName}-${newAudioLengthTracked}`);
                mpTrackIntervalListen(article, newAudioLengthTracked);
                this.setState({
                    audioLengthTracked: newAudioLengthTracked,
                    currentTime: audio.currentTime,
                    loading: false,
                });
                return;
            }

            this.setState({ currentTime: audio.currentTime, loading: false });
        };

        audio.onloadedmetadata = () => this.setState({ length: audio.duration, loading: false });

        audio.onplay = () => this.setState({ playing: true });

        audio.onpause = () => this.setState({ playing: false });

        audio.onended = () => event(EventCategories.EMBED_PLAYER, EventActions.END, metricName, length);

        audio.onloadstart = () => this.setState({ loading: true });

        this.setState({ audio });
    };

    public onTogglePlay = () => {
        const { metricName, article } = this.props;
        const { audio, playing, speed, currentTime } = this.state;

        if (!audio) {
            return;
        }

        // FIX ISSUE ON IE
        setTimeout(() => (audio.playbackRate = speed), 50);

        if (playing) {
            audio.pause();
            audio.onplay = () => this.setState({ playing: false });
            event(EventCategories.EMBED_PLAYER, EventActions.PAUSE, metricName);
            mpTrackButtonClick(article, MpTrackingButtons.PAUSE);
            return;
        }

        // show loading if it's fetching the audio yet (IOS)
        if (currentTime <= 0) {
            this.setState({ loading: true });
        }

        audio.play();
        audio.onplay = () => this.setState({ playing: true });
        event(EventCategories.EMBED_PLAYER, EventActions.PLAY, metricName);
        mpTrackButtonClick(article, MpTrackingButtons.PLAY);
    };

    public onSpeedUp = () => {
        const { audio } = this.state;
        const { article } = this.props;

        if (audio && audio.playbackRate < MAX_SPEED) {
            audio.playbackRate = +(audio.playbackRate + 0.1).toFixed(2);
            this.setState({ speed: audio.playbackRate });
            event(EventCategories.EMBED_PLAYER, EventActions.TAP, EventLabels.EMBED_PLAYER_SPEED_UP, audio.playbackRate);
            mpTrackButtonClick(article, MpTrackingButtons.ADJUST_SPEED);
        }
    };

    public onSpeedDown = () => {
        const { audio } = this.state;
        const { article } = this.props;

        if (audio && audio.playbackRate > MIN_SPEED) {
            audio.playbackRate = +(audio.playbackRate - 0.1).toFixed(2);
            this.setState({ speed: audio.playbackRate });
            event(EventCategories.EMBED_PLAYER, EventActions.TAP, EventLabels.EMBED_PLAYER_SPEED_DOWN, audio.playbackRate);
            mpTrackButtonClick(article, MpTrackingButtons.ADJUST_SPEED);
        }
    };

    public onChange = (value: number) => {
        const { audio } = this.state;

        if (audio) {
            audio.currentTime = value;
            this.setState({ currentTime: audio.currentTime });
        }
    };

    public onScrubberClick = () => {
        mpTrackButtonClick(this.props.article, MpTrackingButtons.SCRUB);
    };

    public onClickFeedback = () => {
        const { metricName, article } = this.props;

        event(EventCategories.EMBED_PLAYER, EventActions.TAP, `${EventLabels.EMBED_PLAYER_FEEDBACK}-${metricName}`);
        mpTrackButtonClick(article, MpTrackingButtons.FEEDBACK);
    };

    public onClickListenOnNoa = () => {
        const { metricName, article } = this.props;

        event(EventCategories.EMBED_PLAYER, EventActions.TAP, `${EventLabels.EMBED_PLAYER_LISTEN_ON_NOA}-${metricName}`);

        mpTrackButtonClick(article, MpTrackingButtons.NAVIGATE_TO_NOA);
    };

    public trackOnPlayerLoad = () => {
        const { metricName } = this.props;

        event(EventCategories.EMBED_PLAYER, EventActions.LOAD, metricName);
    };

    public notifiyAudioFound = async () => {
        let notifyCount = 0;

        // Trigger this numerous times as sometimes the JS script which receives the message loads slowly
        while (notifyCount <= 10) {
            window.parent.postMessage(AUDIO_FOUND, "*");

            const additionalWait = 200 * notifyCount; // 0, 200, 400,..., 2000
            const totalWaitTime = 1000 + additionalWait;
            await sleep(totalWaitTime);
            notifyCount += 1;
        }
    };

    public componentDidMount = () => {
        if (!this.props.audioUrl) {
            return;
        }

        this.notifiyAudioFound();
        this.setAudioDefault();

        window.addEventListener("beforeunload", () => {
            if (this.state.currentTime > 0) {
                event(EventCategories.EMBED_PLAYER, EventActions.END, this.props.metricName, this.state.currentTime);
            }
        });

        this.trackOnPlayerLoad();
    };

    public componentDidUpdate = (prevProps: IProps) => {
        const { audioUrl } = this.props;

        if (prevProps.audioUrl !== audioUrl) {
            this.notifiyAudioFound();
            this.setAudioDefault();
        }
    };

    public isUrlAllowed = () => {
        if (!document.referrer) {
            return true;
        }

        for (const url of config.embed.domainRestrictedUrls) {
            if (document.referrer.indexOf(url) !== -1) {
                return true;
            }
        }

        return false;
    };

    public isHbr = () => {
        return this.props.publisherKey === config.embed.publishers.hbr;
    };

    public isAllowedRender = () => {
        const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

        if (!this.isHbr() || isWebView() || env !== "production") {
            return true;
        }

        if (isIFrame() && this.isUrlAllowed()) {
            return true;
        }

        return true;
    };

    public getPlayerProps = (): any => {
        // tslint:disable: object-literal-sort-keys
        return {
            feedbackEmail: this.props.feedbackEmail,
            linkToNoa: this.props.linkToNoa,
            noaUrl: this.props.noaUrl,
            title: this.props.title,
            subtitle: this.props.subtitle,
            imageUrl: this.props.imageUrl,
            color: this.props.color,
            bgColor: this.props.bgColor,
            listenColor: this.props.listenColor,
            controlColor: this.props.controlColor,
            currentTime: this.state.currentTime,
            length: this.state.length || this.props.article.audioLength,
            speed: this.state.speed,
            playing: this.state.playing,
            publisherKey: this.props.publisherKey,
            queryParams: this.props.queryParams,
            onClickFeedback: this.onClickFeedback,
            onClickListenOnNoa: this.onClickListenOnNoa,
            onSpeedUp: this.onSpeedUp,
            onSpeedDown: this.onSpeedDown,
            onTogglePlay: this.onTogglePlay,
            onChange: this.onChange,
            onClick: this.onScrubberClick,
            titleColor: this.props.titleColor,
            timeColor: this.props.timeColor,
            progressBgColor: this.props.progressBgColor,
            speedColor: this.props.speedColor,
            noaLinkColor: this.props.noaLinkColor,
            noaLinkHighlightColor: this.props.noaLinkHighlightColor,
            progressBorderColor: this.props.progressBorderColor,
        };
    };

    public getPlayerToRender = () => {
        const props = this.getPlayerProps();

        if (this.isHbr()) {
            return <this.LazyPlayerSpecial {...props} />;
        }

        switch (this.props.version) {
            case 1:
                return <this.LazyStandardPlayer {...props} />;
            case 2:
                return <this.LazyStandardPlayerV2 {...props} />;
            case 3:
                return <this.LazyStandardPlayerV3 {...props} />;
            case 4:
                return <this.LazyPlayerSpecial {...props} />;
            default:
                return <this.LazyStandardPlayer {...props} />;
        }
    };

    public render() {
        const { audioUrl } = this.props;

        if (!this.isAllowedRender()) {
            return;
        }

        return (
            <React.Suspense fallback={<></>}>
                {this.getPlayerToRender()}
                <audio id={PLAYER_HTML_ID} controls={false} preload="none">
                    <source src={audioUrl} type="audio/ogg" />
                    <source src={audioUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </React.Suspense>
        );
    }
}

export default EmbedPlayer;
