const breakpoints = {
    extraLarge: 1500,
    large: 1199,
    medium: 992,
    minuscule: 360,
    small: 767,
    smaller: 650,
    tiny: 575,
    tinyier: 490,
};

const theme = {
    breakpoints,
    embedTitle: "#383D3D",
    iconColor: "#6D6D6D",
    tertiaryColor: "#039BE5",
    textColor: "#6D6D6D",
    title: "#000000",
};

export default theme;
