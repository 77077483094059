import * as React from "react";

interface IProps {
    className: string;
}

// tslint:disable: max-line-length
class IconEmbedPlayV2 extends React.Component<IProps> {

    public render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="126.142" height="139.104" viewBox="0 0 126.142 139.104" className={this.props.className}>
                <g id="PlayIcon2" transform="translate(-3547.429 -7149.929)">
                    <g id="Group_653" data-name="Group 653" transform="translate(-228.5 41.5)" opacity="0">
                        <line id="Line_4" data-name="Line 4" y1="105" transform="translate(3866.5 7125.5)" stroke-linecap="round" stroke-width="25" />
                        <line id="Line_5" data-name="Line 5" y1="105" transform="translate(3804.5 7125.5)" stroke-linecap="round" stroke-width="25" />
                    </g>
                    <g id="Group_654" data-name="Group 654" transform="translate(22 4.5)">
                        <line id="Line_5-2" data-name="Line 5" y1="105" transform="translate(3542.5 7162.5)" stroke-linecap="round" stroke-width="25" />
                        <line id="Line_6" data-name="Line 6" x1="92" y1="53" transform="translate(3542.5 7162.5)" stroke-linecap="round" stroke-width="25" />
                        <line id="Line_7" data-name="Line 7" x1="92" y2="52" transform="translate(3542.5 7215.5)" stroke-linecap="round" stroke-width="25" />
                        <circle id="Ellipse_63" data-name="Ellipse 63" cx="37.5" cy="37.5" r="37.5" transform="translate(3535 7177)" />
                    </g>
                </g>
            </svg>
        );
    }
}

export default IconEmbedPlayV2;
