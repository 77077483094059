const common = {
    api: {
        routes: {
            articlePlayer: "/v1/player/article",
        },
        url: "http://localhost:3000",
    },
    domain: "localhost",
    embed: {
        domainRestrictedUrls: ["lucas-noa.github.io/", "hbr.org/"],
        publishers: {
            hbr: "uir3z2",
            politico: "pol34q",
            imd: "imd95a",
            fastCompany: "q65swa",
            conversation: "x84olp",
            bcg: "bcg11b",
            atlantic: "atl55x",
            tls: "m21csq",
            mittr: "olt23a",
            noema: "n0e13g",
        },
        specialUrls: ["hbr.org/"],
    },
    routes: {
        publishers: "/publishers",
    },
    site: {
        url: "https://newsoveraudio.com",
    },
    appStore: {
        ios: "https://apps.apple.com/ie/app/noa-journalism-narrated/id1135037186",
        android: "https://play.google.com/store/apps/details?id=com.newsoveraudio.noa",
    },
};

const development = {
    ...common,
    api: {
        ...common.api,
        url: "https://app-server-dev.newsoveraudio.com",
    },
    domain: ".newsoveraudio.com",
    site: {
        url: "https://app-dev.newsoveraudio.com",
    },
};

const production = {
    ...common,
    api: {
        ...common.api,
        url: "https://api.newsoveraudio.com",
    },
    domain: ".newsoveraudio.com",
};

let config = common;
const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

if (env === "production") {
    config = production;
} else if (env === "development") {
    config = development;
}

export default config;
