// tslint:disable-next-line: ordered-imports
import * as React from "react";
import "react-app-polyfill/ie11";
import { render } from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./components/App";
import EmbedPlayerWrapper from "./components/EmbedPlayerWrapper";

const rootElement = document.getElementById("root");

const routing = (
    <Router>
        <div>
            <Route exact path="/" component={App} />
            <Route path="/v*" component={EmbedPlayerWrapper} />
        </div>
    </Router>
);

render(routing, rootElement);
