export const toMMSS = (time: number) => {
  const timeRounded = Math.round(time);
  let minutes = `${Math.floor(timeRounded / 60)}`;
  let seconds = `${timeRounded - +minutes * 60}`;

  if (+minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (+seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
};
